.loading {
  opacity: 0.5;
  pointer-events: none;
}

.card {
  border-bottom-style: none !important;
}

.card-body {
  overflow-x: auto;
  height: 87vh;
}

.card-body a {
  text-decoration: none !important;
  color: black !important;
}

.page-item.active .page-link {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
